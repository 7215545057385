.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1040;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: var(--gray-8);
    border-radius: 15px;
    padding: 3rem 4.2rem;
    min-height: 50vh;
    width: 100%;
    max-width: 34vw;
}


.modal-content.full {
    max-width: 80vw;

}

.modal-content.view {
    max-width: 50vw;
}

.modal-content.medium {
    max-width: 45vw;
}

.modal-main {
    padding-top: 3rem;
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: 1px;
    scrollbar-width: none;
}

.modal-main.theater {
    overflow-y: auto;
}

.modal-main::-webkit-scrollbar {
    /* display: none; */
    /* width: 1px;
    height: 100%; */
}