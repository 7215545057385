nav {
    background-color: var(--gray-8);
    border-bottom   : 1.5px solid var(--primary-color);
    display         : flex;
    height          : 10vh;
    justify-content : center;
    align-items     : center;
}

nav .nav_container {
    width          : 80vw;
    display        : flex;
    justify-content: end;
    align-items    : center;
}

#brand img {
    height: 4vh;
}

.nav_user_wrapper {
    display    : flex;
    align-items: center;
    position   : relative;
    height     : 10vh;
}

.nav_user_wrapper .user_wrapper {
    border       : 1px solid #202020;
    border-radius: 50%;
    overflow     : hidden;

    display        : flex;
    justify-content: center;
    align-items    : center;
}

.nav_user_wrapper .user_wrapper img {
    width : 2.5vw;
    height: 2.5vw;
}

.nav_user_wrapper .user_details {
    margin-left : 0.9027777777777778vw;
    margin-right: 1.736111111111111vw;
}

.nav_user_wrapper .user_details h3 {
    font-style : normal;
    font-weight: bold;
    font-size  : 1.5625vh;
    line-height: 1.953125vh;
    color      : white;
}

.nav_user_wrapper .user_details p {
    font-style : normal;
    font-weight: 200;
    font-size  : 1.5625vh;
    line-height: 1.953125vh;
    color      : var(---light-gray);
}

.nav_user_wrapper .user_profile_btn,
.nav_user_wrapper .user_profile_btn img {
    width   : 2.291666666666667vw;
    height  : 3.22265625vh;
    overflow: hidden;
}

.nav_user_wrapper .user_profile_btn {
    width           : 2vw;
    height          : 2vw;
    border-radius   : 50%;
    border: 1px solid var(--gray-4);
}

.nav_user_wrapper .user_profile_btn:hover {
    background-color: var(--gray-4);
}

.nav_user_wrapper .user_profile_btn svg {
    fill: var(--gray-4);
}

.nav_user_wrapper .user_profile_btn:hover svg {
    fill: var(--black);
}

/* USER DROPDOWN */

.user_dropdown {
    position  : absolute;
    top       : 100%;
    right     : 0;
    width     : 208px;
    width     : 100%;
    opacity   : 0;
    visibility: hidden;
    z-index   : 1;

}

.user_dropdown.show {
    opacity   : 1;
    visibility: visible;
}

.user_dropdown .dropdown_inner {
    background        : var(--white);
    padding           : .8rem 0;
    border-radius     : .8rem;
    border            : 1px solid var(--navy-blue);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.20);
    box-shadow        : 0px 0px 10px 1px rgba(0, 0, 0, 0.20);
}

.user_dropdown a,
.user_dropdown button {
    display        : block;
    font-weight    : 500;
    font-size      : 1.8rem;
    line-height    : 2.4rem;
    color          : var(--gray-6);
    text-transform : capitalize;
    text-align     : left;
    padding        : 1rem 2rem;
    width          : 100%;
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.user_dropdown a:hover,
.user_dropdown button:hover {
    background-color: var(--gray-3);
    color           : var(--black);
}

.user_dropdown a svg path {
    fill: var(--navy-blue);
}

.user_dropdown button svg {
    fill: var(--navy-blue);
}